import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import Vuelidate from "vuelidate";
import numberFormatter from "./utils/formatNumber";
import dateFormatter from "./utils/formatDate";
import "@/services/firebase";
// import VueDatePicker from "@mathieustan/vue-datepicker";
// import "@mathieustan/vue-datepicker/dist/vue-datepicker.min.css";
import VueToastr from "vue-toastr";
import VueAuthentication from "@/services/index.js";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import logger from "js-console-logger";

// Core Vue setup
Vue.config.productionTip = process.env.VUE_APP_ENVIRONMENT === "production";
Vue.config.performance = process.env.VUE_APP_ENVIRONMENT !== "production";
Vue.config.devtools = process.env.VUE_APP_ENVIRONMENT !== "production";

import { consoleConfig } from "js-console-logger";
window.logger = logger;
consoleConfig.setDisableConsole(process.env.NODE_ENV === "production");

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faCcVisa,
  faCcMastercard,
  faCcAmex,
} from "@fortawesome/free-brands-svg-icons";
import {
  faFileAlt,
  faCheckSquare,
  faBell,
  faCheckCircle,
  faAddressCard,
  faCalendar,
  faCreditCard,
  faFilePdf,
  faImages,
  faClock,
  faHandshake,
} from "@fortawesome/free-regular-svg-icons";
import {
  faAt,
  faLock,
  faEyeSlash,
  faEye,
  faHome,
  faList,
  faDollarSign,
  faSignInAlt,
  faSignOutAlt,
  faCalculator,
  faChevronDown,
  faMapMarkerAlt,
  faAngleDown,
  faAngleUp,
  faHandPaper,
  faCloudDownloadAlt,
  faBed,
  faBlender,
  faCouch,
  faSink,
  faChair,
  faChalkboard,
  faBicycle,
  faBoxOpen,
  faSuitcase,
  faTrash,
  faSearch,
  faCog,
  faUser,
  faTimes,
  faExclamation,
  faWarehouse,
  faTruck,
  faSync,
  faCheck,
  faExchangeAlt,
} from "@fortawesome/free-solid-svg-icons";

library.add([
  faAt,
  faLock,
  faEyeSlash,
  faEye,
  faHome,
  faList,
  faFileAlt,
  faDollarSign,
  faCheck,
  faCheckSquare,
  faSignInAlt,
  faSignOutAlt,
  faCalculator,
  faBell,
  faChevronDown,
  faCheckCircle,
  faMapMarkerAlt,
  faAngleDown,
  faAngleUp,
  faHandPaper,
  faCloudDownloadAlt,
  faBed,
  faBlender,
  faCouch,
  faSink,
  faChair,
  faChalkboard,
  faBicycle,
  faBoxOpen,
  faSuitcase,
  faTrash,
  faSearch,
  faCog,
  faUser,
  faTimes,
  faAddressCard,
  faExclamation,
  faCalendar,
  faWarehouse,
  faTruck,
  faSync,
  faCreditCard,
  faCcVisa,
  faCcMastercard,
  faCcAmex,
  faFilePdf,
  faImages,
  faExchangeAlt,
  faClock,
  faHandshake,
  faTimes,
]);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(Vuelidate);
Vue.use(numberFormatter);
Vue.use(dateFormatter);
// Vue.use(VueDatePicker);
Vue.use(VueAuthentication);
Vue.use(VueToastr, {
  defaultPosition: "toast-bottom-center",
  defaultTimeout: 5000,
  defaultClickClose: true,
  defaultCloseOnHover: false,
  defaultProgressBar: false,
  defaultStyle: {
    width: "70%",
    "text-align": "center",
    "font-weight": 600,
    "border-radius": "20px",
  },
});

// Vue.use(VueDatePicker, {
//   lang: "es",
// });

if (process.env.VUE_APP_ENVIRONMENT !== "development") {
  Sentry.init({
    Vue,
    dsn: "https://d57ebfce070a4a6ab666d8cb0f5094be@o992869.ingest.sentry.io/6155313",
    release: `box2box-customer-webapp@${process.env.VUE_APP_VERSION}`,
    environment: process.env.VUE_APP_ENVIRONMENT,
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.7,
    ignoreErrors: ["Network Error"],
    logErrors: true,
    tracingOptions: {
      trackComponents: true,
    },
  });
}

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
